<template>
  <div class="aides bg-white">
    <div id="aides" style="position: relative; top: -110px; left: 0"></div>
    <div class="aides__header">
      <h2>Qui est aides&nbsp;?</h2>

      <div class="aides__header__tag">
        <div class="aides__header__tag__column">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
        <div class="aides__header__tag__column hide_on_mobile">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
        <div class="aides__header__tag__column hide_on_mobile">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
      </div>
    </div>
    <img
      src="@/assets/img/camille_spire.webp"
      srcset="
        @/assets/img/camille_spire_1400.webp 1400w,
        @/assets/img/camille_spire_1200.webp 1200w,
        @/assets/img/camille_spire_1080.webp 1080w,
        @/assets/img/camille_spire_828.webp   828w,
        @/assets/img/camille_spire_750.webp   750w,
        @/assets/img/camille_spire_640.webp   640w,
        @/assets/img/camille_spire_320.webp   320w
      "
      sizes="(max-width: 480px) 100vw,
            (max-width: 1024px) 50vw,
            33vw"
      alt="Camille Spire, Présidente AIDES"
    />

    <div class="aides__word">
      <span>Mot de la présidente</span>
      <div class="aides__word__citation flex">
        <blockquote>
          La lutte contre le VIH/sida est loin d&apos;&ecirc;tre
          termin&eacute;e. 200 000 personnes vivent avec le VIH en France et 24
          000 d&rsquo;entre elles l&rsquo;ignorent. Il reste d&rsquo;importants
          efforts &agrave; faire en mati&egrave;re de diagnostic, donc de
          d&eacute;pistage, d&rsquo;acc&egrave;s aux traitements et aux soins,
          mais aussi de pr&eacute;vention. Face &agrave; cette
          r&eacute;alit&eacute;, nous militons encore et toujours pour un
          meilleur acc&egrave;s aux droits et &agrave; la sant&eacute;, nous
          agissons sur le terrain partout en France contre toutes les
          discriminations qui font le lit de l&apos;&eacute;pid&eacute;mie, pour
          transformer durablement la soci&eacute;t&eacute;.<br /><br />

          Nous n&rsquo;en avons pas fini avec le VIH, mais partout o&ugrave;
          l&rsquo;&eacute;pid&eacute;mie se trouve, elle nous trouve sur sa
          route. La lutte engag&eacute;e par les militants-es de la
          premi&egrave;re heure de AIDES est d&eacute;sormais renforc&eacute;e
          par de nouvelles g&eacute;n&eacute;rations. Ce collectif engag&eacute;
          nous permet depuis quarante ans de gagner des batailles contre le sida
          et les h&eacute;patites virales.<br /><br />

          C&apos;est ensemble avec votre soutien que nous faisons avancer la
          lutte, chaque jour, &agrave; travers nos actions. Ce combat va bien
          au-del&agrave; de la journ&eacute;e mondiale de lutte contre le sida,
          le 1er d&eacute;cembre.<br /><br />

          Militer, Agir, Transformer, tels sont nos objectifs quotidiens pour
          briser la chaine de l&apos;&eacute;pid&eacute;mie. Pour y parvenir,
          votre soutien et vos dons sont indispensables. A chaque don la lutte
          avance, le VIH recule.
        </blockquote>
      </div>

      <span id="name">Camille Spire, Présidente AIDES</span>
    </div>

    <div class="aides__num">
      <span>AIDES en quelques chiffres</span>
      <div class="aides__num__block">
        <div class="aides__num__block__item">
          <img src="../assets/img/goute.svg" alt="goute aides" />
          <span data-end="30851" id="purecounter_1" class="purecounter"></span>
          <p>Tests de dépistage au&nbsp;VIH</p>
        </div>
        <div class="verticale_barre_mobile"></div>
        <div class="verticale_barre_desktop"></div>
        <div class="aides__num__block__item">
          <img src="../assets/img/preservatifs.svg" alt="preservatifs aides" />
          <span
            data-end="1551584"
            id="purecounter_2"
            class="purecounter"
          ></span>
          <p>Préservatifs distribués</p>
        </div>
        <div class="verticale_barre_desktop"></div>
        <div class="aides__num__block__item">
          <img src="../assets/img/haut-parleur.svg" alt="haut-parleur aides" />
          <span data-end="30189" id="purecounter_3" class="purecounter"></span>
          <p>Actions menées au&nbsp;global</p>
        </div>
        <div class="verticale_barre_mobile"></div>
        <div class="aides__num__block__item">
          <img src="../assets/img/handshake.svg" alt="handshake aides" />
          <span data-end="428782" id="purecounter_4" class="purecounter"></span>
          <p>Personnes rencontrées</p>
        </div>
        <div class="verticale_barre_desktop"></div>
        <div class="aides__num__block__item">
          <img src="../assets/img/poing.svg" alt="poing aides" />
          <span data-end="2036" id="purecounter_5" class="purecounter"></span>
          <p>Militants-es</p>
        </div>
        <div class="verticale_barre_mobile"></div>
        <div class="verticale_barre_desktop"></div>
        <div class="aides__num__block__item">
          <img src="../assets/img/france.svg" alt="france aides" />
          <span data-end="72" id="purecounter_6" class="purecounter"></span>
          <p>Lieux de Mobilisation</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PureCounter from "@srexi/purecounterjs";

export default {
  mounted() {
    this.counter();
  },

  methods: {
    counter() {
      const purecounters = Array.from(
        document.querySelectorAll(".purecounter")
      );
      purecounters.forEach((item) => {
        new PureCounter({
          selector: "#" + item.id,
          start: 0,
          duration: 3,
          end: item.dataset.end,
          delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
          once: true, // Counting at once or recount when the element in view [boolean]
          pulse: false, // Repeat count for certain time [boolean:false|seconds]
          decimals: 0, // How many decimal places to show. [uint]
          legacy: true, // If this is true it will use the scroll event listener on browsers
          filesizing: false, // This will enable/disable File Size format [boolean]
          currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
          formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
          separator: true, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@media (max-width: 915px) {
  .bg-white {
    padding: 0 0;
  }

  .aides__word > span:nth-child(4) {
    font-size: 5vw;
  }
}
.aides {
  background-color: var(--color-white);
  text-align: center;
  padding-top: 119px;
  @media (max-width: 915px) {
    padding-top: 15%;
    padding-bottom: 15%;
  }

  img {
    width: 100%;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
    @media (max-width: 915px) {
      flex-direction: column;
      margin-bottom: 50px;
    }
    h2 {
      color: var(--color-black);
      font-size: 3.5vw;
      @media (max-width: 915px) {
        font-size: 7vw;
        padding-bottom: 10px;
      }
    }
    span {
      color: var(--color-black);
    }

    &__tag {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-right: 50px;
      @media (max-width: 915px) {
        margin-right: 0;
      }

      &__column {
        display: flex;
        flex-direction: column;
        font-family: "RobotoL", "Adjusted Arial Fallback L", sans-serif;
        font-size: 18px;
        color: var(--color-white);
        text-transform: uppercase;

        & > span {
          font-size: 1vw;
          color: var(--color-black);
          @media (max-width: 915px) {
            font-size: 3vw;
          }
        }
      }

      @media (max-width: 915px) {
        .hide_on_mobile {
          display: none;
        }
      }
    }
  }

  &__word {
    padding-top: 2%;
    width: 65%;
    margin: auto;
    @media (max-width: 915px) {
      width: 80%;
      padding-top: 7%;
    }

    span {
      font-family: "RobotoB", "Adjusted Arial Fallback B", sans-serif;
      font-size: 2.2rem;
    }

    #name {
      color: var(--bg-color-black);
      font-size: var(--text-size);
      margin: 5% auto 0;
    }

    &__citation {
      blockquote {
        margin: 3% 0 5%;
      }
      @media (max-width: 915px) {
        blockquote:before {
          top: -40px;
        }
      }
    }
  }

  &__num {
    padding-top: 5%;
    margin: auto;

    span {
      font-family: "RobotoB", "Adjusted Arial Fallback B", sans-serif;
      font-size: 2rem;
    }

    @media (max-width: 915px) {
      width: 90%;
      padding-top: 15%;
    }

    &__block {
      max-width: 50%;
      display: flex;
      flex-wrap: wrap;
      display: flex;
      margin: auto;
      padding-top: 3%;
      @media (max-width: 915px) {
        max-width: 100%;
      }

      .verticale_barre_desktop {
        width: 2px;
        position: relative;
        top: 80px;
        height: 36px;
        background-color: var(--color-black);
        @media (max-width: 1400px) {
          display: none;
        }
      }

      .verticale_barre_mobile {
        width: 2px;
        position: relative;
        top: 80px;
        height: 36px;
        background-color: var(--color-black);
        @media (min-width: 1400px) {
          display: none;
        }
      }

      &__item {
        width: 33%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-bottom: 2%;
        @media (max-width: 1400px) {
          width: 49%;
        }

        span {
          padding: 5% 0 0 0;
        }
        p {
          padding: 2% 0;
          width: 90%;
          text-transform: uppercase;
        }

        span {
          font-size: 2rem;
          font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;

          color: var(--bg-color-red);
        }
        img {
          max-width: 100px;
          aspect-ratio: 1/1;
        }
      }
    }
  }
}
</style>
