var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"militants"},[_c('CtaDon'),_c('menuView',{staticClass:"fixed"}),_vm._m(0),_c('div',{staticClass:"militants__pres bg-blacke"},[_c('img',{staticClass:"mobile",attrs:{"src":'/assets/img/militants/' + _vm.cover,"alt":_vm.name + 'de Aides'}}),_c('h2',[_vm._v(_vm._s(_vm.name))]),_c('hr'),_c('div',{staticClass:"militants__pres__loc"},[(_vm.mobile)?_c('img',{attrs:{"src":'/' + _vm.map,"alt":'location' + _vm.name}}):_c('img',{attrs:{"src":'/' + _vm.mapblanc,"alt":'location' + _vm.name}}),_c('p',[_vm._v(_vm._s(_vm.location))]),_c('p',[_vm._v(_vm._s(_vm.annees))])]),_c('div',{staticClass:"militants__description flex"},[_vm._m(1),_c('h3',[_vm._v(" Soutenez "+_vm._s(_vm.name)+" et tous-tes nos militants-es dans leurs actions. ")]),_c('a',{staticClass:"button_anim donatelink",attrs:{"href":'https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source=' +
          _vm.utm_source +
          '&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=' +
          _vm.amount,"target":"_blank"}},[_vm._v("Je fais un don")])])]),_c('div',{staticClass:"militants__actions"},[_c('div',{staticClass:"militants__actions__text"},[_c('h2',[_vm._v("Ses actions")]),_c('p',[_vm._v(_vm._s(_vm.actions))]),_c('button',{staticClass:"button_anim donatelink",on:{"click":function($event){return _vm.redirect()}}},[_vm._v(" Aider "+_vm._s(_vm.name)+" dans ses actions ")])]),_c('div',{staticClass:"militants__actions__image"},[_c('img',{attrs:{"src":'/assets/img/equivalence/' + _vm.equivalence,"alt":"visuel des militants"}}),_c('a',{staticClass:"mobile__a donatelink",attrs:{"href":'https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source=' +
          _vm.utm_source +
          '&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=' +
          _vm.amount,"target":"_blank"}},[_vm._v("Aider "+_vm._s(_vm.name)+" dans ses actions")])])]),_c('div',{staticClass:"millitants__back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{attrs:{"id":"img_militants","src":require("@/assets/img/back_to_militants.webp"),"alt":"Retour vers nos militants"}}),_c('img',{attrs:{"src":require("@/assets/img/back_arrow.svg"),"alt":"précedent"}}),_c('span',[_vm._v("Retour vers nos militants-es")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"militants__header bg-blacke"},[_c('h2',[_vm._v("Nos militants-es")]),_c('div',{staticClass:"militants__header__tag"},[_c('div',{staticClass:"militants__header__tag__column"},[_c('span',[_vm._v("#Militer AGIR TRANSFORMER")]),_c('span',[_vm._v("#Militer AGIR TRANSFORMER")]),_c('span',[_vm._v("#Militer AGIR TRANSFORMER")])]),_c('div',{staticClass:"militants__header__tag__column hide_on_mobile"},[_c('span',[_vm._v("#Militer AGIR TRANSFORMER")]),_c('span',[_vm._v("#Militer AGIR TRANSFORMER")]),_c('span',[_vm._v("#Militer AGIR TRANSFORMER")])]),_c('div',{staticClass:"militants__header__tag__column hide_on_mobile"},[_c('span',[_vm._v("#Militer AGIR TRANSFORMER")]),_c('span',[_vm._v("#Militer AGIR TRANSFORMER")]),_c('span',[_vm._v("#Militer AGIR TRANSFORMER")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"militants__description__citation"},[_c('blockquote',{attrs:{"id":"para"}})])
}]

export { render, staticRenderFns }