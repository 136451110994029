<template>
  <div class="menu bg-black">
    <nav>
      <div id="open">
        <div class="barre"></div>
        <div class="barre"></div>
        <div class="barre"></div>
      </div>
      <img
        id="logo_aides"
        src="/assets/img/logo/logo_aides.svg"
        alt="logo_aides"
        @click="anchors('top')"
      />
      <ul class="hide show">
        <li @click="anchors('militants'), anchor()">Nos militants-es</li>
        <li @click="anchors('donner'), anchor()">Pourquoi donner&nbsp;?</li>
        <li @click="anchors('aides'), anchor()">Qui est aides&nbsp;?</li>
      </ul>
      <div class="vide"></div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "menuView",

  mounted() {
    this.menu();
    var urlcourante = document.location.href;
    this.url = urlcourante.split("/").pop();
  },

  methods: {
    anchors(anchor) {
      localStorage.setItem("anchor", anchor);

      var redirec = sessionStorage.getItem("link")
      if(redirec === null){
        sessionStorage.setItem("link", null)
      }

      if (this.url === sessionStorage.getItem("link").split("_map")[0]) {
        
        const id = sessionStorage.getItem("link").split("_map");
        if ("militants/" + this.url === "militants/" + id[0]) {
          this.$router.push("/#" + anchor);
        }
      } else {
        console.log('oui')
        const el = document.getElementById(anchor);

        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    },

    anchor() {
      if (window.matchMedia("(max-width: 915px)").matches) {
        const open = document.getElementById("open");
        let show = document.querySelector(".show");
        if (show.style.transform === "translateX(0%)") {
          show.style.transform = "translateX(-120%)";
          document.body.style.overflow = "auto";
        } else {
          show.style.transform = "translateX(0%)";
          document.body.style.overflow = "auto";
        }
        open.classList.toggle("rotate");
      }
    },

    menu() {
      const open = document.getElementById("open");
      const nav_img = document.getElementById("logo_aides");
      open.addEventListener("click", () => {
        const logo_src = nav_img.src;
        let show = document.querySelector(".show");
        if (show.style.transform === "translateX(0%)") {
          show.style.transform = "translateX(-120%)";
          document.body.style.overflow = "auto";
        } else {
          show.style.transform = "translateX(0%)";
          document.body.style.overflow = "hidden";
        }

        open.classList.toggle("rotate");
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bg-black {
  @media (max-width: 915px) {
    padding: 0 2rem;
  }
}

.menu {
  color: var(--color-white);
  position: fixed;
  z-index: 10;
  transition: all 200ms linear;
  background-color: rgba(18, 18, 18, 0.8);
  backdrop-filter: blur(10px);

  @media (max-width: 915px) {
    background-color: var(--bg-color-black);
  }
  .hide {
    @media (max-width: 915px) {
      display: none;
    }
  }

  .show {
    @media (max-width: 915px) {
      transition: all 0.5s ease;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      gap: 7%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      transform: translateX(-120%);
      background-color: var(--color-black);
      z-index: 10;
      li {
        font-size: 30px;
        font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;
      }
    }
  }

  #open {
    display: none;
    @media (max-width: 915px) {
      display: flex;
      flex-direction: column;
      z-index: 11;

      .barre {
        width: 30px;
        height: 1px;
        background-color: var(--color-white);
        margin: 3px 0;
        transition: all 0.5s ease;
      }
    }
  }

  .rotate {
    & > .barre:nth-child(1) {
      transform: rotate(45deg) translate(3px, 3px);
    }

    & > .barre:nth-child(2) {
      opacity: 0;
    }

    & > .barre:nth-child(3) {
      transform: rotate(-45deg) translate(7px, -7px);
    }
  }

  nav {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 915px) {
      justify-content: space-between;
    }

    img {
      cursor: pointer;
    }

    #logo_aides {
      width: 191px;
      @media (max-width: 915px) {
        width: 110px;
      }
    }

    .vide {
      display: none;

      @media (max-width: 915px) {
        display: block;
        width: 30px;
      }
    }

    img {
      z-index: 11;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 110px;

      li {
        font-family: "RobotoB", "Adjusted Arial Fallback B", sans-serif;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        width: fit-content;
        padding: 0.3em 0;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.2em;
          background-color: var(--bg-color-red);
          transform: scaleX(0);
          transform-origin: center;
          transition: transform 400ms ease-out;
        }
        &:hover::after {
          transform: scaleX(0.5);
        }

        a {
          text-decoration: none;
        }
      }
    }
  }
}
</style>