<template>
  <div class="video bg-black">
    <div class="video__header">
      <h2>Le film</h2>

      <div class="video__header__tag">
        <div class="video__header__tag__column">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
        <div class="video__header__tag__column hide_on_mobile">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
        <div class="video__header__tag__column hide_on_mobile">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
      </div>
    </div>
    <div class="video__main">
        <iframe id="video" width="560" height="313" src="https://www.youtube.com/embed/FalKJrqfP28?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <!--<vue-plyr>
        <div class="plyr__video-embed">
          <iframe
            src="https://www.youtube.com/watch?v=FalKJrqfP28"
            allowfullscreen
            allowtransparency
            allow="autoplay"
            controls="false"
            style="
              --plyr-color-main: #d60812;
              --plyr-range-track-height: 2px;
              --plyr-font-family: 'RobotoL', 'Adjusted Arial Fallback L',
                sans-serif;
            "
          ></iframe>
        </div>
      </vue-plyr>-->
    </div>
    <!--<div class="count">
      <img src="../assets/img/countdown_txt.svg" alt="aides count" />
      <Countdown :date="end"></Countdown>
    </div>-->

    <div class="video__bottom">
      <p>
        Chaque jour, nos militants-es agissent sur le terrain. Grâce à vos dons,
        leurs actions s’intensifient et l’épidémie recule.
      </p>
    </div>
  </div>
</template>

<script>
import Plyr from "plyr";
import Countdown from "@/components/Countdown.vue";

export default {
  name: "VideoView",

  components: { Countdown },

  data() {
    return {
      end: new Date("2022-12-01T00:00:00"),
    };
  },

  mounted() {
    const player = new Plyr("#player");
  },
};
</script>

<style scoped lang="scss">
.bg-black {
  padding-bottom: 5%;
}
.count {
  background-image: url(../assets/img/countdown.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 50px 0;
  @media (max-width: 768px) {
    padding: 25px 0;
  }

  img {
    width: 70%;
    max-width: 700px;
    margin: auto;
    @media (max-width: 768px) {
      width: 90%;
    }
  }

  .countdown {
    display: flex;
    justify-content: center;
    color: white;
    display: flex;
    align-items: flex-end;
  }
}
.video {
  background-image: url(../assets/img/texture.webp);
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  padding-top: 119px;

  @media (max-width: 915px) {
    height: 100%;
    padding-bottom: 15%;
    padding-top: 15%;
  }
  .video__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
    @media (max-width: 915px) {
      flex-direction: column;
      margin-bottom: 50px;
    }
    h2 {
      font-size: 3.5vw;
      @media (max-width: 915px) {
        font-size: 7vw;
        padding-bottom: 10px;
      }
    }

    .video__header__tag {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-right: 50px;
      @media (max-width: 915px) {
        margin-right: 0;
      }

      .video__header__tag__column {
        display: flex;
        flex-direction: column;
        font-family: "RobotoL", "Adjusted Arial Fallback L", sans-serif;
        font-size: 18px;
        color: var(--color-white);
        text-transform: uppercase;
        & > span {
          font-size: 1vw;
          color: var(--color-white);
          @media (max-width: 915px) {
            font-size: 3vw;
          }
        }
      }

      @media (max-width: 915px) {
        .hide_on_mobile {
          display: none;
        }
      }
    }
  }
  .video__main {
    width: 60%;
    margin: auto;
    position: relative;
    padding-bottom: 34.25%;
    @media (max-width: 915px) {
      width: 100%;
      padding-bottom: 56.25%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .video__bottom {
    width: 50%;
    margin: 30px auto 10px;
    color: var(--color-white);
    font-family: "RobotoR", "Adjusted Arial Fallback", sans-serif;
    font-size: 15px;
    text-align: center;
    @media (max-width: 915px) {
      width: 90%;
    }
  }
}
</style>