<template>
  <div class="defiscaliser bg-white">
    <div class="defiscaliser__top flex">
      <div class="defiscaliser__top__title flex">
        <span>4</span>
        <h3 class="flex-align-center">
          Étapes pour<br />
          défiscaliser<br />
          votre don
        </h3>
      </div>
      <div class="defiscaliser__top__description flex-align-center">
        <img src="@/assets/img/logo_don.svg" alt="logo don aides" />
        <p>
          Savez-vous que <span>votre don est déductible à 66%</span> de vos
          impôts sur le revenu&nbsp;? Faites un don
          <span>avant le 31 décembre</span> pour en bénéficier. Suivez
          simplement les étapes ci-dessous.
        </p>
      </div>
    </div>

    <div class="defiscaliser__slider desktop">
      <img src="../assets/img/defiscaliser.svg" alt="defiscaliser don" />
    </div>
    <div class="defiscaliser__slider mobile">
      <img src="../assets/img/defiscaliser_mobile.svg" alt="defiscaliser don" />
    </div>
    <div class="defiscaliser__swipe">
      <p>Glissez vers la droite</p>
      <img src="@/assets/img/fleche.svg" alt="fleche aides" />
    </div>
  </div>
</template>

<script>
export default {
  name: "desfiscaliserView",
};
</script>

<style scoped lang="scss">
@media (min-width: 915px) {
  .mobile {
    display: none;
  }
}

.desktop {
  display: block;
  @media (max-width: 915px) {
    display: none;
  }
}

.defiscaliser {
  padding-top: 119px;
  padding-bottom: 5%;
  background-color: var(--color-white);
  @media (max-width: 915px) {
    height: 100%;
    padding-bottom: 15%;
    padding-top: 15%;
  }
  &__top {
    gap: 8%;
    @media (max-width: 915px) {
      flex-direction: column;
      gap: 0;
    }

    &__title {
      gap: 6%;
      justify-content: center;
      span {
        font-size: 13vw;
        font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;
        @media (max-width: 915px) {
          font-size: 6rem;
        }
      }

      h3 {
        color: var(--color-black);
        font-size: 3vw;
        @media (max-width: 915px) {
          font-size: 24px;
        }
      }
    }

    &__description {
      width: 60%;
      gap: 20px;

      img {
        width: 13%;
      }
      @media (max-width: 915px) {
        padding-top: 30px;
        width: 100%;

        img {
          max-width: 30%;
        }
      }
      p {
        span {
          color: var(--color-white);
          background-color: var(--bg-color-red);
        }
        @media (max-width: 915px) {
          font-size: var(--text-size);
          text-align: center;
        }
      }
    }
  }

  &__slider {
    margin: 0 auto;
    text-align: center;
    @media (max-width: 915px) {
      overflow-x: scroll;
    }
    img {
      max-width: 90%;
      height: calc(100vh - 125px);
      @media (max-width: 915px) {
        max-width: none;
        padding-top: 30px;
        height: 100%;
        width: 1300px;
      }
    }
  }

  &__swipe {
    display: none;
    margin: 15px auto 0;
    text-align: center;
    @media (max-width: 915px) {
      display: block;
    }
    p {
      font-size: 18px;
      font-family: "RobotoB", "Adjusted Arial Fallback B", sans-serif;
      color: var(--color-black);
    }

    img {
      position: relative;
      left: 55px;
      animation: 1.5s swipe infinite ease-in;
    }

    @keyframes swipe {
      0% {
        opacity: 0;
        transform: translateX(0);
      }
      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        transform: translateX(10px);
      }
    }
  }
}
</style>