<template>
  <div class="header bg-black" id="top">
    <div class="header__main">
      <img src="@/assets/img/header_mobile.webp" alt="logo_aides" />
      <div class="header__main__title">
        <h1>Militer<br />Agir<br />Transformer</h1>
      </div>

      <div class="header__main__don">
        <div class="header__main__don__title">
          <span>Je donne</span>
          <p>Pour que le vih recule.</p>
        </div>

        <div class="header__main__don__price">
          <button
            @click="prices(40)"
            class="button_price button_color"
            id="numbers40"
          >
            40&nbsp;€
          </button>
          <button
            @click="prices(60)"
            class="button_price button_color"
            id="numbers60"
          >
            60&nbsp;€
          </button>
          <button
            @click="prices(80)"
            class="button_price button_color"
            id="numbers80"
          >
            80&nbsp;€
          </button>
          <button
            @click="prices(100)"
            class="button_price button_color"
            id="numbers100"
          >
            100&nbsp;€
          </button>
        </div>

        <div class="header__main__don__aide" id="aide">
          <p>
            Votre aide nous permettra de financer
            {{ price * 5 }}&nbsp;préservatifs.
          </p>
        </div>

        <div class="header__main__don__montant">
          <input
            type="number"
            placeholder="Montant libre"
            v-model="price"
            @keyup="montant()"
            id="montant"
          />
          <p>€</p>
        </div>

        <div class="header__main__don__impots" id="impots">
          <p>
            Savez-vous que votre don est dédutible à 66% de vos impôts sur le
            revenu&nbsp;?
            <span id="don" class="hide"
              >Votre don ne vous coûtera donc réellement que
              {{ Math.round(price * 0.34) }}€.</span
            >
          </p>
        </div>

        <div class="header__main__don__submit">
          <button class="button_anim donatelink" @click="redirect()">FAIRE UN DON</button>
        </div>
      </div>
      <div class="header__main__scroll">
        <span>Scroll</span>
        <img src="../assets/img/scroll.svg" alt="scroll aides" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerView",

  data() {
    return {
      price: "",
      utm_source: "",
    };
  },

  mounted(){
  },

  methods: {
    prices(number) {
      this.price = number;
      const buttons = Array.from(document.querySelectorAll(".button_price"));

      buttons.forEach((item) => {
        if (item.id === "numbers" + number) {
          item.classList.add("active");
          item.classList.remove("button_color");
          document.getElementById("aide").style.display = "block";
          document.getElementById("don").style.display = "block";
        } else {
          item.classList.remove("active");
          item.classList.add("button_color");
        }
      });
    },

    montant() {
      document.getElementById("aide").style.display = "none";

      const buttons = Array.from(document.querySelectorAll(".button_price"));

      buttons.forEach((item) => {
        item.classList.add("button_color");
        item.classList.remove("active");
      });

      if (this.price === "0" || this.price === "") {
        document.getElementById("don").style.display = "none";
      }
    },

    redirect() {
      this.utm_source = localStorage.getItem("utm_source");
      if (this.price > 0 && this.price !== "") {
        var montant = (this.price * 100).toString();
        var url =
          "https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source="+this.utm_source+"&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=" +
          montant
      } else {
        var url =
          "https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source="+this.utm_source+"&utm_medium=referral&utm_campaign=23CFA&frequency=once";
      }
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style scoped lang="scss">
.active {
  background-color: var(--bg-color-red);
  color: #ffffff;
  border: none;
  font-family: "RobotoB", "Adjusted Arial Fallback B", sans-serif;
  padding: 3% 5%;
  border-radius: 3px;
}

@media (max-width: 915px) {
  .bg-black {
    padding: 0;
  }
}
.header {
  background-image: url(../assets/img/texture.webp);
  background-size: cover;
  background-position: center;
  padding-top: 119px;
  height: 100vh;
  @media (max-width: 915px) {
    height: 100%;
    padding-top: 105px;
  }
  &__main {
    background-image: url("../assets/img/header.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 90%;

    @media (max-width: 915px) {
      background-image: none;
    }

    img {
      @media (max-width: 915px) {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:first-child {
        display: none;
        @media (max-width: 915px) {
          display: block;
        }
      }
    }

    &__title {
      display: flex;
      flex-direction: column;

      h1 {
        padding: 25px 35px 0 35px;
        color: var(--color-black);
        @media (max-width: 915px) {
          color: var(--color-white);
          font-size: 2.2rem;
          padding-bottom: 5%;
        }
        @media (max-height: 700px) and (min-width: 900px){
          font-size: 35px;
          line-height: normal
        }
      }
    }

    &__don {
      width: 15%;
      margin: 0px 0px 0 35px;
      display: flex;
      flex-direction: column;
      @media (max-width: 1250px) {
        width: 25%;
      }
      @media (min-width: 1250px) and (max-width: 1400px) {
        width: 20%;
      }
      @media (max-width: 915px) {
        width: 100%;
        margin: 0;
        padding: 0 35px 15px 35px;
      }

      &__title {
        padding: 15px;
        background-color: var(--bg-color-red);
        margin-bottom: 10px;

        span {
          color: var(--color-white);
          font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;
          font-size: 2rem;
          text-transform: uppercase;
        }
        p {
          color: var(--color-white);
          text-transform: uppercase;
          font-size: 15px;
        }
      }

      &__price {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        .button_color {
          background-color: var(--color-white);
          padding: 3% 5%;
          border-radius: 3px;
          border: 1px solid var(--color-grey);
          font-family: "RobotoR", "Adjusted Arial Fallback", sans-serif;
        }
      }

      &__aide {
        display: none;
        background-color: var(--bg-color-red);
        border-radius: 3px;
        color: var(--color-white);
        padding: 10px 15px;
        text-align: center;
        margin-bottom: 10px;

        p {
          font-size: 15px;
        }
      }

      &__montant {
        margin-bottom: 10px;
        display: flex;

        input {
          width: 85%;
          background-color: var(--color-white);
          padding: 10px 15px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        p {
          display: flex;
          align-items: center;
          width: 15%;
          background-color: var(--color-grey);
          padding: 3% 5%;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          font-size: var(--text-size);
        }
      }

      &__impots {
        background-color: var(--color-grey);
        color: var(--color-black);
        border-radius: 3px;
        padding: 5px 15px;
        text-align: center;
        margin-bottom: 10px;

        p {
          font-size: 14px;

          span {
            font-size: 14px;
            display: none;
          }
        }
      }

      &__submit {
        text-align: center;
        background-color: var(--bg-color-red);
        border-radius: 3px;
        color: var(--color-white);
        font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;

        button {
          width: 100%;
          height: 50px;
        }
      }
    }

    &__scroll {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 13%;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      @media (max-width: 915px) {
        position: static;
        bottom: 0;
        left: 0;
        transform: translateX(0);
        text-align: center;
      }
      span {
        margin-bottom: 10px;
        @media (max-width: 915px) {
          margin-bottom: 0;
        }
      }
      img {
        animation: 1s scroll infinite;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        @media (max-width: 915px) {
          animation: 1s scrollMobile infinite;
          position: static;
          justify-content: center;
          margin: auto;
          transform: translateX(0);
          top: auto;
          left: auto;
          width: 50px;
          height: 50px;
        }
      }
    }

    @keyframes scrollMobile {
      0% {
        opacity: 0;
        transform: translateY(0);
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: translateY(10px);
      }
    }

    @keyframes scroll {
      0% {
        opacity: 0;
        top: 80%;
      }
      70% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
</style>