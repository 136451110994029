import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Militants from '../views/Militants.vue'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/militants/:id',
    name: 'militants',
    component: Militants
  },

]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = { x: 0, y: 0 }

    if (savedPosition) {
      position = savedPosition
    }
    var toHash = to.hash.split('?')[0];
    if (toHash) {
      return { selector: toHash };
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {

        var urlcourante = document.location.href;
        var url = urlcourante.split("/")[3];
        if (url === "#militants" || url === "#donner" || url === "#aides") {

        } else {
          resolve(position)
        }
      }, 300)
    })
  }
})

export default router
