<template>
  <div class="slider bg-black">
    <div id="militants" style="position: relative; top: -130px; left: 0"></div>
    <div class="video__header">
      <h2>Nos militants-es</h2>

      <div class="video__header__tag">
        <div class="video__header__tag__column">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
        <div class="video__header__tag__column hide_on_mobile">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
        <div class="video__header__tag__column hide_on_mobile">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
      </div>
    </div>
    <div id="intro" class="glide">
      <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides">
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(0)"
              class="militantlink_Martin"
            >
              <img
                src="/assets/img/militants/martin.webp"
                alt="Martin de Aides"
              />
              <h3>Martin</h3>
            </div>
          </li>

          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(1)"
              class="militantlink_Christophe"
            >
              <img
                src="/assets/img/militants/christophe.webp"
                alt="Christophe de Aides"
              />
              <h3>Christophe</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(2)"
              class="militantlink_Emmanuel"
            >
              <img
                src="/assets/img/militants/emmanuel.webp"
                alt="Emmanuel de Aides"
              />
              <h3>Emmanuel</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(3)"
              class="militantlink_Karelle"
            >
              <img
                src="/assets/img/militants/karelle.webp"
                alt="Karelle de Aides"
              />
              <h3>Karelle</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(4)"
              class="militantlink_Franck"
            >
              <img
                src="/assets/img/militants/franck.webp"
                alt="Franck de Aides"
              />
              <h3>Franck</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(5)"
              class="militantlink_Nadia"
            >
              <img src="assets/img/militants/nadia.webp" alt="Nadia de Aides" />
              <h3>Nadia</h3>
            </div>
          </li>

          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(6)"
              class="militantlink_Frankie"
            >
              <img
                src="/assets/img/militants/frankie.webp"
                alt="Franckie de Aides"
              />
              <h3>Frankie</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(7)"
              class="militantlink_Margot"
            >
              <img
                src="/assets/img/militants/margot.webp"
                alt="Margot de Aides"
              />
              <h3>Margot</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(8)"
              class="militantlink_Adrien"
            >
              <img
                src="/assets/img/militants/adrien.webp"
                alt="Adrien de Aides"
              />
              <h3>Adrien</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(9)"
              class="militantlink_Esther"
            >
              <img
                src="/assets/img/militants/cory.webp"
                alt="Esther Judith de Aides"
              />
              <h3>Esther Judith</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(10)"
              class="militantlink_Nicolas"
            >
              <img
                src="/assets/img/militants/nicolas.webp"
                alt="Nicolas de Aides"
              />
              <h3>Nicolas</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(11)"
              class="militantlink_Ali"
            >
              <img src="/assets/img/militants/ali.webp" alt="Ali de Aides" />
              <h3>Ali</h3>
            </div>
          </li>
          <li class="glide__slide">
            <div
              data-ref="hero[el]"
              @click="militants(12)"
              class="militantlink_Anatole"
            >
              <img
                src="/assets/img/militants/anatole.webp"
                alt="Anatole de Aides"
              />
              <h3>Anatole</h3>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="arrow">
      <button id="prev">
        <img src="../assets/img/arrow_left.svg" alt="flèche du slider gauche" />
      </button>
      <div class="separate"></div>
      <button id="next">
        <img
          src="../assets/img/arrow_right.svg"
          alt="flèche du slider droite"
        />
      </button>
    </div>

    <p>
      Découvrez les profils de nos militants-es qui agissent dans toute la
      France.
    </p>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";
import axios from "axios";

export default {
  name: "Carousel",

  data() {
    return {
      startAt: 0,
      utm_source: "",
    };
  },

  mounted() {
    this.utm_source = this.$route.query.utm_source;
    if (this.utm_source === undefined) {
      this.utm_source = "23CFALP";
    }
    if (
      window.matchMedia("(min-width: 915px)").matches &&
      sessionStorage.getItem("id_slider") === null
    ) {
      this.startAt = 6;
    } else if (sessionStorage.getItem("id_slider") === null) {
      this.startAt = 0;
    } else {
      this.startAt = sessionStorage.getItem("id_slider");
    }
    this.slider();
    let anchor = localStorage.getItem("anchor");
    const el = document.getElementById(anchor);
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  },

  methods: {
    militants(num) {
      localStorage.setItem("anchor", "militants");
      axios.get("militants.json").then((response) => {
        this.militant = response.data.militants;
        this.militant.forEach((element) => {
          if (element.id === num) {
            localStorage.setItem(
              "map",
              "assets/img/map/black/" + element.map + ".png"
            );
            localStorage.setItem(
              "mapblanc",
              "assets/img/map/white/" + element.mapblanc + ".png"
            );
            localStorage.setItem("equivalence", element.equivalence);
            localStorage.setItem("name", element.name);
            localStorage.setItem("verbatim", element.verbatim);
            localStorage.setItem("actions", element.actions);
            localStorage.setItem("location", element.location);
            localStorage.setItem("annees", element.annees);
            localStorage.setItem("cover", element.cover);
            sessionStorage.setItem("id_slider", element.id);
            localStorage.setItem("amount", element.amount * 100);
            sessionStorage.setItem("link", element.map);
            const id = element.map.split("_map");

            this.$router.push({
              name: "militants",
              params: {
                id: id[0],
              },
            });
          }
        });
      });
    },

    slider() {
      var c = '[data-ref="hero[el]"]';
      var nextButton = document.querySelector("#next");
      var prevButton = document.querySelector("#prev");
      var glide = new Glide("#intro", {
        type: "slider",
        focusAt: "center",
        startAt: this.startAt,
        perView: 5,
        peek: 100,
        gap: 15,
        rewind: true,
        dragThreshold: 10,
        swipeThreshold: 10,
        breakpoints: {
          480: { gap: 15, peek: 10, perView: 1 },
          915: { perView: 2 },
          1360: { perView: 3, startAt: 7 },
          1600: { perView: 4, startAt: 7 },
          1960: { perView: 5, startAt: 7 },
          5000: { gap: 30, perView: 5, startAt: 7 },
        },
      });

      if (nextButton) {
        nextButton.addEventListener("click", function (event) {
          event.preventDefault();

          glide.go(">");
        });
      }

      if (prevButton) {
        prevButton.addEventListener("click", function (event) {
          event.preventDefault();

          glide.go("<");
        });
      }

      glide.mount({
        Coverflow: function (e, t, n) {
          var i = {
            tilt: function (e) {
              (e.querySelector(c).style.transform =
                "perspective(1200px) rotateY(0deg)"),
                this.tiltPrevElements(e),
                this.tiltNextElements(e);
            },
            tiltPrevElements: function (e) {
              for (
                var t = (function (e) {
                    var t = [];
                    if (e) for (; (e = e.previousElementSibling); ) t.push(e);
                    return t;
                  })(e),
                  n = 0;
                n < t.length;
                n++
              ) {
                var i = t[n].querySelector(c);
                (i.style.transformOrigin = "100% 50%"),
                  (i.style.transform = "perspective(1200px) rotateY(".concat(
                    15 * Math.max(n, 2),
                    "deg)"
                  ));
              }
            },
            tiltNextElements: function (e) {
              for (
                var t = (function (e) {
                    var t = [];
                    if (e) for (; (e = e.nextElementSibling); ) t.push(e);
                    return t;
                  })(e),
                  n = 0;
                n < t.length;
                n++
              ) {
                var i = t[n].querySelector(c);
                (i.style.transformOrigin = "0% 50%"),
                  (i.style.transform = "perspective(1200px) rotateY(".concat(
                    -15 * Math.max(n, 2),
                    "deg)"
                  ));
              }
            },
          };
          return (
            n.on(["mount.after", "run"], function () {
              i.tilt(t.Html.slides[e.index]);
            }),
            i
          );
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.shows {
  display: block;
  transition: all 0.5s ease;
  transform: translateY(-100%);
}

.arrow {
  display: flex;
  justify-content: center;
  gap: 1%;
  align-items: center;

  @media (max-width: 915px) {
    gap: 5%;
  }

  .separate {
    width: 5%;
    height: 1px;
    background-color: #fff;
    @media (max-width: 915px) {
      width: 15%;
    }
  }

  #next,
  #prev {
    scale: 1;
    transition: all 0.1s ease-in;
    @media (min-width: 915px) {
      &:hover {
        scale: 1.2;
      }
    }
  }
}

.slider {
  background-image: url(../assets/img/texture.webp);
  padding-top: 150px;
  background-size: cover;
  background-position: center;
  scroll-behavior: smooth;
  @media (max-width: 915px) {
    padding-bottom: 15%;
    padding-top: 15%;
  }
  & > p {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: var(--color-white);
    padding: 5% 0;
  }
}

.video__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;

  @media (max-width: 915px) {
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;
  }
  h2 {
    font-size: 3.5vw;
    @media (max-width: 915px) {
      font-size: 7vw;
      padding-bottom: 10px;
    }
  }

  .video__header__tag {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 915px) {
      margin-right: 0;
    }

    .video__header__tag__column {
      display: flex;
      flex-direction: column;
      font-family: "RobotoL", "Adjusted Arial Fallback L", sans-serif;
      color: var(--color-white);
      text-transform: uppercase;
      & > span {
        font-size: 1vw;
        color: var(--color-white);
        @media (max-width: 915px) {
          font-size: 3vw;
        }
      }
    }

    @media (max-width: 915px) {
      .hide_on_mobile {
        display: none;
      }
    }
  }
}
.glide {
  z-index: 0;
}
.glide__slides {
  margin-bottom: 80px;
  margin-top: 55px;
  position: relative;
  width: 100%;
  list-style: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -ms-touch-action: pan-Y;
  touch-action: pan-Y;
  padding: 0;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  will-change: transform;
  overflow: visible;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }

  h3 {
    padding-top: 10px;
  }
}

.glide__slide {
  position: relative;
  //overflow: hidden;
  img {
    transition: all 0.2s linear;
  }

  &:before {
    position: absolute;
    display: block;
    content: "D\0000E9couvrir ses actions";
    font-family: "RobotoB", "Adjusted Arial Fallback B", sans-serif;
    color: white;
    font-size: 18px;
    transform: translate(-50%, -50%);
    top: 42%;
    left: 50%;
    opacity: 0;
    z-index: 100;
    transition: all 200ms linear;
  }
  &:hover {
    cursor: url(../assets/img/cursor_cross.svg), auto;
    img {
      filter: brightness(50%);
    }
  }
  &:hover:before {
    opacity: 1;
  }
}

.glide__slide img {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

[data-ref="hero[el]"] {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  will-change: transform;
}

.toto {
  display: block;
  width: 300px;
  height: 300px;
  transform-origin: 100% 50%;
  transform: perspective(1500px) rotateY(45deg);
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  will-change: transform;
  background: url(https://picsum.photos/300/300?random=2);
}
</style>
