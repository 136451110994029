<template>
  <div class="militants">
    <CtaDon />
    <menuView class="fixed" />
    <div class="militants__header bg-blacke">
      <h2>Nos militants-es</h2>

      <div class="militants__header__tag">
        <div class="militants__header__tag__column">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
        <div class="militants__header__tag__column hide_on_mobile">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
        <div class="militants__header__tag__column hide_on_mobile">
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
          <span>#Militer AGIR TRANSFORMER</span>
        </div>
      </div>
    </div>

    <div class="militants__pres bg-blacke">
      <img
        :src="'/assets/img/militants/' + cover"
        :alt="name + 'de Aides'"
        class="mobile"
      />
      <h2>{{ name }}</h2>
      <hr />
      <div class="militants__pres__loc">
        <img v-if="mobile" :src="'/' + map" :alt="'location' + name" />
        <img v-else :src="'/' + mapblanc" :alt="'location' + name" />
        <p>{{ location }}</p>
        <p>{{ annees }}</p>
      </div>

      <div class="militants__description flex">
        <div class="militants__description__citation">
          <blockquote id="para"></blockquote>
        </div>

        <h3>
          Soutenez {{ name }} et tous-tes nos militants-es dans leurs actions.
        </h3>
        <a
          :href="
            'https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source=' +
            utm_source +
            '&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=' +
            amount
          "
          class="button_anim donatelink"
          target="_blank"
          >Je fais un don</a
        >
      </div>
    </div>

    <div class="militants__actions">
      <div class="militants__actions__text">
        <h2>Ses actions</h2>
        <p>{{ actions }}</p>
        <button class="button_anim donatelink" @click="redirect()">
          Aider {{ name }} dans ses actions
        </button>
      </div>
      <div class="militants__actions__image">
        <img
          :src="'/assets/img/equivalence/' + equivalence"
          alt="visuel des militants"
        />
        <a
          :href="
            'https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source=' +
            utm_source +
            '&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=' +
            amount
          "
          class="mobile__a donatelink"
          target="_blank"
          >Aider {{ name }} dans ses actions</a
        >
      </div>
    </div>
    <div @click="$router.go(-1)" class="millitants__back">
      <img
        id="img_militants"
        src="@/assets/img/back_to_militants.webp"
        alt="Retour vers nos militants"
      />
      <img src="@/assets/img/back_arrow.svg" alt="précedent" />
      <span>Retour vers nos militants-es</span>
    </div>
  </div>
</template>

  <script>
import menuView from "../components/menu.vue";
import CtaDon from "../components/CtaDon.vue";

export default {
  components: {
    menuView,
    CtaDon,
  },

  data() {
    return {
      map: localStorage.getItem("map"),
      mapblanc: localStorage.getItem("mapblanc"),
      name: localStorage.getItem("name"),
      location: localStorage.getItem("location"),
      annees: localStorage.getItem("annees"),
      cover: localStorage.getItem("cover"),
      actions: localStorage.getItem("actions"),
      verbatim: localStorage.getItem("verbatim"),
      equivalence: localStorage.getItem("equivalence"),
      amount: localStorage.getItem("amount"),
      obj: "",
      mobile: false,
      baseUrl: "",
      utm_source: "",
    };
  },

  mounted() {
    this.onShowMilitants();
    setTimeout(()=>{
      this.utm_source = localStorage.getItem("utm_source");
    }, 2000)

    const withLineBreak = { paragraph: this.verbatim };
    document.getElementById("para").innerHTML = withLineBreak.paragraph;
    this.baseUrl = window.location.host;

    if (window.matchMedia("(min-width: 915px)").matches) {
      document.querySelector(
        ".militants__pres"
      ).style.backgroundImage = `url(/assets/img/cover-militants/${this.cover})`;
      this.mobile = true;
    }
  },

  methods: {
    onShowMilitants() {
      var name = sessionStorage.getItem("link").split("_map")[0];
      window.gtag &&
        gtag("config", "UA-1717694-3", {
          page_title: "Témoignage" + name,
          page_path: "/militants/" + name,
        });
    },

    redirect() {
      this.utm_source = localStorage.getItem("utm_source");
      window
        .open(
          "https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source=" +
            this.utm_source +
            "&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=" +
            this.amount,
          "_blank"
        )
        .focus();
    },
  },
};
</script>

  <style scoped lang="scss">
#para {
  span {
    font-size: 0.5rem;
  }
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
}
.mobile {
  display: none;
  @media (max-width: 915px) {
    display: block;
  }
}

.bg-blacke {
  @media (max-width: 915px) {
    padding: 0 3%;
  }
}

.mobile__a {
  background-color: var(--bg-color-red);
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: "PanoramaB";
  text-transform: uppercase;
  color: var(--color-white);
  padding: 20px 30px;
  margin-top: 5%;
  font-size: 3vw;
  text-decoration: none;

  @media (min-width: 915px) {
    display: none;
  }
}

.militants {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
  background-image: url(../assets/img/texture.webp);
  background-size: cover;
  background-position: center;
  z-index: 9;
  padding-top: 119px;

  .militants__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-bottom: 38px;

    @media (max-width: 915px) {
      flex-direction: column;
      margin-bottom: 50px;
    }

    &__arrow {
      position: fixed;
      top: 135px;
      left: 0;
      width: 5%;
      height: 119px;
      background-color: var(--color-black);
      z-index: 10;
      cursor: pointer;
      padding-left: 20px;
      @media (max-width: 915px) {
        display: none;
      }

      &__left {
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid var(--color-white);
        @media (max-width: 915px) {
          border-right: 20px solid transparent;
          border-left: 20px solid var(--color-white);
          border-top: 20px solid var(--color-white);
          border-bottom: 20px solid transparent;
        }
      }
    }

    h2 {
      font-size: 3.5vw;
      @media (max-width: 915px) {
        font-size: 7vw;
        padding-bottom: 10px;
      }
    }

    .militants__header__tag {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-right: 50px;
      @media (max-width: 915px) {
        margin-right: 0;
      }

      .militants__header__tag__column {
        display: flex;
        flex-direction: column;
        font-family: "RobotoL";
        font-size: 18px;
        color: var(--color-white);
        text-transform: uppercase;
        & > span {
          font-size: 1vw;
          color: var(--color-white);
          @media (max-width: 915px) {
            font-size: 3vw;
          }
        }
      }

      @media (max-width: 915px) {
        .hide_on_mobile {
          display: none;
        }
      }
    }
  }

  &__pres {
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: right;
    padding: 3% 5% 15% 5%;
    width: 90%;
    height: 100%;
    margin: auto;
    @media (max-width: 915px) {
      height: auto;
    }

    h2 {
      font-size: var(--big-size-title);
      color: var(--color-black);
      @media (max-width: 915px) {
        color: var(--color-white);
        padding-top: 5%;
      }
    }

    p {
      &:nth-child(2) {
        font-family: "RobotoR";
        color: var(--color-black);
        @media (max-width: 915px) {
          color: var(--color-white);
        }
      }

      &:nth-child(3) {
        font-family: "RobotoR";
        color: var(--color-black);
      }
      @media (max-width: 915px) {
        color: var(--color-white);
      }
    }
    hr {
      width: 60px;
      height: 3px;
      background-color: var(--color-black);
      border: none;
      margin-bottom: 2%;
      @media (max-width: 915px) {
        background-color: var(--color-white);
      }
    }
    &__loc {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      p {
        color: var(--color-black);
        text-transform: uppercase;

        &:nth-child(2) {
          display: flex;
          align-items: center;
          @media (max-width: 915px) {
            color: var(--color-white);
          }
        }
        &:nth-child(3) {
          @media (max-width: 915px) {
            color: var(--color-white);
          }
        }

        &:nth-child(2)::after {
          content: "";
          display: block;
          width: 2px;
          height: 30px;
          margin: 0 0 0 20px;
          background-color: var(--color-black);
          @media (max-width: 915px) {
            background-color: var(--color-white);
          }
        }
      }

      @media (max-width: 915px) {
        padding-top: 5%;
      }
    }
  }
  &__description {
    flex-direction: column;
    width: 25%;
    @media (max-width: 1400px) {
      width: 50%;
    }

    @media (max-width: 915px) {
      width: 90%;
      margin: auto;
    }

    &__citation {
      display: flex;
      flex-direction: row;

      @media (max-width: 915px) {
        color: var(--color-white);
      }

      blockquote {
        margin: 40px 0;
        font-family: "RobotoR";

        &:before {
          top: -40px;
        }

        &:after {
        }
      }

      span {
        @media (max-width: 1200px) {
          margin-left: -6%;
        }
      }
    }
    span {
      color: var(--bg-color-red);
      font-size: 5rem;
      display: flex;
      justify-content: flex-end;
    }

    h3 {
      font-size: 1.5rem;
      color: var(--color-black);
      margin: 5% 0;
      text-transform: uppercase;
      @media (max-width: 915px) {
        color: var(--color-white);
      }
    }

    a {
      background-color: var(--bg-color-red);
      color: var(--color-white);
      border: none;
      text-align: center;
      padding: 5% 5%;
      margin-top: 5%;
      font-size: 1.3rem;
      font-family: "PanoramaB";
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        background-color: var(--bg-color-redhover);
      }
    }
  }

  &__actions {
    background-image: url(../assets/img/texture.webp);
    display: flex;
    gap: 5%;
    align-items: center;

    justify-content: space-between;
    padding: 5% 5%;
    @media (max-width: 915px) {
      padding: 14% 5%;
      flex-wrap: wrap;
    }

    &__text {
      width: 50%;
      h2 {
        font-size: var(--big-size-title);
        color: var(--color-white);
      }
      p {
        font-family: "RobotoR";
        color: var(--color-white);
        font-size: 1.3rem;
      }
      button {
        color: var(--color-white);
        border: none;
        padding: 3% 5%;
        margin-top: 3%;
        text-align: center;
        font-size: 1.3rem;
        font-family: "PanoramaB";
        cursor: pointer;
        text-transform: uppercase;
      }

      @media (max-width: 915px) {
        button {
          display: none;
        }
      }

      @media (max-width: 880px) {
        width: 90%;
      }
    }
    &__image {
      display: flex;
      justify-content: space-between;
      width: 37%;

      @media (min-width: 915px) and (max-width: 1580px) {
        padding-top: 5%;
      }

      img {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 915px) {
        flex-direction: column;
        align-items: center;
        width: 100%;

        img {
          width: calc(100% - 30px);
          margin-top: 30px;
        }
      }
    }
  }

  .millitants__back {
    padding-bottom: 30px;
    background-image: url(../assets/img/texture.webp);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    #img_militants {
      opacity: 0.3;
      transition: all 200ms linear;
      &:hover {
        opacity: 1;
      }
      @media (max-width: 915px) {
        opacity: 1;
        max-width: 30%;
      }
    }

    img {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      color: var(--color-white);
      text-transform: uppercase;
      font-family: "RobotoR";
      font-size: 11px;
    }
  }
}

.militants__pres__loc > p:nth-child(2) {
  font-family: "RobotoB";
}
</style>
