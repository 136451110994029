import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import  VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'
import vIntersect from "vue-intersect";

 
Vue.use(VueGlide)
Vue.config.productionTip = false





Vue.use(VuePlyr, {
  plyr: {}
})

new Vue({
  vIntersect,
  router,
  render: h => h(App)
}).$mount('#app')
