<template>
  <div class="CtaDon">
    <a
      :href="
        'https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source=' +
        utm_source +
        '&utm_medium=referral&utm_campaign=23CFA&frequency=once'
      "
      target="_blank"
      class="donatelink"
    >
      <img src="@/assets/img/je_fais_undon.svg" alt="faites un don à aides" />
    </a>
  </div>
</template>

<script>
export default {
  name: "menuView",

  data() {
    return {
      utm_source: "",
    };
  },

  mounted() {
    setTimeout(()=>{
      this.utm_source = localStorage.getItem("utm_source");
    }, 2000)

  },
  }

</script>

<style scoped lang="scss">
.CtaDon {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
  width: 5%;
  @media (max-width: 915px) {
    min-width: 35px;
  }
  img {
    width: 100%;
  }
}
</style>