<template>
  <div class="home">
    <menuView id="menubar" class="trigger" />

    <CtaDon />
    <Intersect @leave="inters(false, 'DOWN')" @enter="inters(false, 'UP')">
      <headerView />
    </Intersect>
    <Intersect @leave="inters(true, 'DOWN')" @enter="inters(false, 'UP')">
      <VideoView id="film"/>
    </Intersect>
    <PourquoiDonnerView />
    <div class="paper">
          <img
    src="@/assets/img/papier.webp"

    srcset="@/assets/img/papier_1400.webp 1400w,
            @/assets/img/papier_1200.webp 1200w,
            @/assets/img/papier_1080.webp 1080w,
            @/assets/img/papier_828.webp 828w,
            @/assets/img/papier_750.webp 750w,
            @/assets/img/papier_640.webp 640w,
            @/assets/img/papier_320.webp 320w"

     sizes="(max-width: 480px) 100vw,
            (max-width: 1024px) 50vw,
            33vw"

      alt=""
    />



    </div>
    <Intersect @leave="inters(false, 'DOWN')" @enter="inters(true, 'UP')">
      <defiscaliser />
    </Intersect>
    <Intersect @leave="inters(true, 'DOWN')" @enter="inters(false, 'UP')">
      <militantsVue />
    </Intersect>
    <Intersect @leave="inters(false, 'UP')">
      <aides />
    </Intersect>

    <newsletters />
    <footerView />
  </div>
</template>

<script>
import menuView from "../components/menu.vue";
import CtaDon from "../components/CtaDon.vue";
import headerView from "../components/header.vue";
import VideoView from "../components/VideoView.vue";
import PourquoiDonnerView from "../components/pourquoidonner.vue";
import defiscaliser from "../components/defiscaliser.vue";
import aides from "../components/aides.vue";
import newsletters from "../components/newsletters.vue";
import footerView from "../components/footer.vue";
import militantsVue from "../components/militants.vue";
import Intersect from "vue-intersect";

export default {
  name: "Home",
  components: {
    menuView,
    CtaDon,
    headerView,
    VideoView,
    defiscaliser,
    aides,
    newsletters,
    footerView,
    militantsVue,
    Intersect,
    PourquoiDonnerView,
  },

  data() {
    return {
      navstyle: true,
      scrollDirection: "",
    };
  },

  mounted() {

    var urlcourante = document.location.href;
    this.url = urlcourante.split("/")[3];
    if(this.url === "#militants"){
    document.getElementById("militants").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    }else{}  

    var scrollPos = 0;
    var _this = this;
    window.addEventListener("scroll", function () {
      if (document.body.getBoundingClientRect().top > scrollPos) {
        _this.scrollDirection = "UP";
      } else {
        _this.scrollDirection = "DOWN";
      }
      scrollPos = document.body.getBoundingClientRect().top;
    });
  },

  methods: {


    inters(state, direction) {
      if (direction === this.scrollDirection) {
        const navbar = document.getElementById("menubar");
        const nav_img = document.getElementById("logo_aides");
        if (state) {
          navbar.classList.add("activenav");
          if (window.innerWidth >= 748) {
            nav_img.src = "assets/img/logo/logo_aides_black.svg";
          }
        } else {
          navbar.classList.remove("activenav");
          if (window.innerWidth >= 748) {
            nav_img.src = "assets/img/logo/logo_aides.svg";
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.paper > img {
  width: 100%;
  background-color: var(--color-white);
  @media (max-width: 915px) {
    height: 65px;
  }
}
.activenav {
  @media (min-width: 748px) {
    background: rgb(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    color: var(--color-black);
  }
}
</style>
