<template>
  <div class="newsletters bg-white">
    <div class="newsletters__text">
      <p>
        Suivez toutes les actions et actualités de AIDES en vous inscrivant à la
        newsletter militante.
      </p>
    </div>
    <div class="newsletters__submit">
      <button class="button_anim" @click="redirect()">Je m'inscris</button>
    </div>
  </div>
</template>

<script>
export default{
  data() {
      return {
          utm_source: '',
      };
  },

  mounted(){
  },

  methods:{
    redirect(){
      this.utm_source = localStorage.getItem("utm_source");
      window.open('https://www.aides.org/direct/abonnez-vous-aux-actualites-de-aides?utm_source='+this.utm_source+'&utm_medium=referral&utm_campaign=23CFA', '_blank').focus();
    }
  }
}
</script>

<style scoped lang="scss">
.newsletters {
  background-color: var(--color-white);
  padding: 10% 28%;
  display: flex;
  max-width: 80%;
  margin: auto;
  max-width: 100%;
  @media (max-width: 915px) {
      margin: auto;
      max-width: 100%;
      padding: 3% 35px;
      flex-direction: column;
    }

  &__text {
    width: 65%;
    background-color: var(--color-black);
    @media (max-width: 915px) {
      width: 100%;
      margin-bottom: 0;
    }
    p {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 100%;
      font-size: 1vw;
      color: var(--color-white);
      text-transform: uppercase;
      @media (max-width: 915px) {
      flex-direction: column;
      padding: 5%;
      font-size: 0.8rem;
      font-family: 'RobotoB', sans-serif;
      text-align: center;
      }
    }
    }


  &__submit {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 35%;
    background-color: var(--bg-color-red);

    @media (max-width: 915px) {
      width: 100%;
    }
    button {
      width: 100%;
      font-family: "PanoramaB", 'Adjusted Arial Black Fallback', sans-serif;
      font-size: 1.4vw;
      color: var(--color-white);
      padding: 20px 15px;
      text-transform: uppercase;
      border: none;
      border-radius: 3px;
      @media (max-width: 915px) {
      font-size: 1.5rem;
      padding: 20px 15px;

    }
    }
  }
}
</style>