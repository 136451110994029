<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      utm_source: "",
    };
  },

  mounted() {




    this.utm_source = this.$route.query.utm_source;
    if (this.utm_source === undefined) {
      localStorage.setItem("utm_source", "23CFALP");
    } else {
      localStorage.setItem("utm_source", this.utm_source);
    }
  },

  methods: {},
};
</script>

<style lang="scss">
@import url("assets/scss/main.scss");

#app {
  background-color: var(--color-black);
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

