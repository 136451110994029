<template>
  <div class="pourquoidonner bg-white">
    <div id="donner" style="position: relative; top: -110px; left: 0"></div>
    <div class="pourquoidonner__top flex">
      <div class="pourquoidonner__top__title flex">
        <h3 class="flex-align-center">Pourquoi donner&nbsp;?</h3>
      </div>
      <div class="pourquoidonner__top__description flex-align-center">
        <p>
          Votre soutien est indispensable à la lutte contre le sida. Vos dons
          ont un réel impact sur les actions de nos militants-es&nbsp;!
          <span>Ensemble faisons reculer le sida.</span>
        </p>
      </div>
    </div>

    <div class="pourquoidonner__vih">
      <p>À chaque don, le VIH recule</p>
    </div>

    <div class="pourquoidonner__dons">
      <div class="pourquoidonner__dons__column">
        <img
          src="../assets/img/dons_preservatifs.svg"
          alt="dons pour des préservatifs"
        />
        <a class="button_anim donatelink" :href='"https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source="+utm_source+"&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=4000"' target="_blank">Je fais un don</a>
      </div>
      <div class="pourquoidonner__dons__column">
        <img
          src="../assets/img/dons_depistages.svg"
          alt="dons pour dépisatages vih"
        />
        <a class="button_anim donatelink" :href='"https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source="+utm_source+"&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=8000"' target="_blank">Je fais un don</a>
      </div>
      <div class="pourquoidonner__dons__column">
        <img
          src="../assets/img/dons_entretiens.svg"
          alt="dons pour des entretiens indiduels"
        />
        <a class="button_anim donatelink" :href='"https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source="+utm_source+"&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=15000"' target="_blank">Je fais un don</a>
      </div>
    </div>

    <section class="splide hide" aria-label="...">
      <div class="splide__track">
        <ul class="splide__list">
          <li class="splide__slide">
            <img
              src="../assets/img/dons_preservatifs.svg"
              alt="dons pour des préservatifs"
            />
            <a
              class="button_anim  donatelink"
              :href='"https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source="+utm_source+"&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=4000"'

              target="_blank"
              >Je fais un don</a
            >
          </li>
          <li class="splide__slide">
            <img
              src="../assets/img/dons_depistages.svg"
              alt="dons pour des préservatifs"
            />
            <a
              class="button_anim  donatelink"
              :href='"https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source="+utm_source+"&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=8000"'

              target="_blank"
              >Je fais un don</a
            >
          </li>
          <li class="splide__slide">
            <img
              src="../assets/img/dons_entretiens.svg"
              alt="dons pour des préservatifs"
            />
            <a
              class="button_anim donatelink"
              :href='"https://soutenir.aides.org/cfa2023/?cid=132&lang=fr_FR&utm_source="+utm_source+"&utm_medium=referral&utm_campaign=23CFA&frequency=once&amount=15000"'

              target="_blank"
              >Je fais un don</a
            >
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import Splide from "@splidejs/splide";
export default {
  name: "pourquoidonnerView",

  data() {
      return {
          utm_source: ''
      };
  },

  mounted() {
    setTimeout(()=>{
      this.utm_source = localStorage.getItem("utm_source");
    }, 2000)

    var splide = new Splide(".splide", {
      pagination: false,
    });

    splide.mount();
  },
};
</script>

<style scoped lang="scss">
.splide__slide > img {
  width: 100%;
  height: 100%;
}

.splide {
  &__slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    a {
      width: 50%;
      text-align: center;
      padding: 5% 0;
      color: var(--color-white);
      text-decoration: none;
      text-transform: uppercase;
      font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;
      background-color: var(--bg-color-red);
    }
  }
}

@media (min-width: 915px) {
  .hide {
    display: none;
  }
}

.splide__arrow--next {
  display: none;
}

.pourquoidonner {
  padding-top: 119px;
  background-color: var(--color-white);
  @media (max-width: 915px) {
    height: 100%;
    padding-bottom: 15%;
    padding-top: 15%;
  }
  &__top {
    gap: 8%;
    @media (max-width: 915px) {
      flex-direction: column;
      gap: 0;
    }

    &__title {
      gap: 6%;
      justify-content: center;
      span {
        font-size: 12.5rem;
        font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;
        @media (max-width: 915px) {
          font-size: 6rem;
        }
      }

      h3 {
        color: var(--color-black);
        font-size: 3.5vw;
        padding-bottom: 10px;
        @media (max-width: 915px) {
          font-size: 7vw;
        }
      }
    }

    &__description {
      width: 45%;
      @media (max-width: 915px) {
        width: 100%;
      }
      p {
        @media (max-width: 915px) {
          font-size: var(--text-size);
          text-align: center;
        }

        span {
          background-color: var(--bg-color-red);
          color: var(--color-white);
        }
      }
    }
  }

  &__vih {
    width: 65%;
    margin: 5% auto;
    text-align: center;
    @media (max-width: 915px) {
      width: 100%;
    }

    p {
      font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;
      font-size: 2.5vw;
      @media (max-width: 915px) {
        font-size: 24px;
      }
    }

    p {
      span {
        font-family: "RobotoB", sans-serif;
      }
    }
  }

  &__dons {
    display: flex;
    gap: 5%;
    justify-content: center;
    @media (max-width: 915px) {
      display: none;
    }
    &__column {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      @media (max-width: 915px) {
        width: 100%;
        margin: 5%;
      }

      &:nth-child(2) {
        @media (min-width: 915px) {
          background-image: url(../assets/img/bg_dons.svg);
          background-position-y: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      img {
        width: 100%;
        height: 100%;
        padding-bottom: 10%;
      }

      a {
        width: 50%;
        text-align: center;
        padding: 5% 2%;
        color: var(--color-white);
        text-decoration: none;
        text-transform: uppercase;
        font-family: "PanoramaB", "Adjusted Arial Black Fallback", sans-serif;
      }
    }
  }
}
</style>